import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, Input, signal, ViewChild } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { itemStaggerAnimation } from '@examdojo/animation';
import { PlatformService } from '@examdojo/platform';
import { IonContent } from '@ionic/angular/standalone';
import { SidebarTopPanelComponent } from './sidebar-top-panel/sidebar-top-panel.component';

@Component({
  selector: 'dojo-page-layout',
  imports: [IonContent, SidebarTopPanelComponent, NgTemplateOutlet],
  templateUrl: './page-layout.component.html',
  animations: [itemStaggerAnimation()],
  styleUrl: './page-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.scrolled]': 'this.isScrolled()',
  },
})
export class PageLayoutComponent {
  constructor(private readonly platformService: PlatformService) {}

  @Input() sidebarConfig = {
    showTopPanel: true,
  };

  @Input() wrapInsideIonContent = true;

  @ViewChild(IonContent, { static: true }) ionContent?: IonContent;

  readonly enableScrollTracking = input<boolean>(false);
  readonly isShowBlurTopPanel = input<boolean>(false);
  readonly showHeader = input<boolean>(false);
  readonly isScrolled = signal<boolean>(false);

  readonly state = connectState({
    isMobile: this.platformService.isSmall$,
  });

  onScroll(event: CustomEvent) {
    if (!this.enableScrollTracking()) {
      return;
    }

    this.isScrolled.set(event.detail.scrollTop > 50);
  }
}
