@if (showHeader()) {
  <ng-content select="[ion-header-content]"></ng-content>
}

<ng-template #content>
  <div class="page-layout-container">
    @if (sidebarConfig.showTopPanel) {
      <!-- Top bar for mobile -->
      <div class="page-layout-top-bar-mobile" @itemStaggerAnimation>
        <dojo-sidebar-top-panel />
      </div>
    }

    <!-- Main Content -->
    <div class="page-layout-main">
      @if (isShowBlurTopPanel()) {
        <div class="blur-element"></div>
      }
      <ng-content select="[main-content]"></ng-content>
    </div>

    <!-- Sidebar -->
    @if (!state.isMobile) {
      <div class="page-layout-sidebar">
        @if (sidebarConfig.showTopPanel) {
          <!-- Top bar for desktop -->
          <div class="page-layout-top-bar-desktop">
            <dojo-sidebar-top-panel />
          </div>
        }
        <ng-content select="[sidebar-content]"></ng-content>
      </div>
    }
  </div>
</ng-template>

@if (!wrapInsideIonContent && !state.isMobile) {
  <ng-container *ngTemplateOutlet="content"></ng-container>
} @else {
  <ion-content #ionContent scrollEvents="true" (ionScroll)="onScroll($event)">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ion-content>
}
