import { Injectable } from '@angular/core';
import { Query } from '@examdojo/state';
import { map } from 'rxjs';
import { UserMarksState } from './user-marks.model';
import { UserMarksStore } from './user-marks.store';

@Injectable({ providedIn: 'root' })
export class UserMarksQuery extends Query<UserMarksState> {
  constructor(protected override readonly store: UserMarksStore) {
    super(store);
  }

  readonly dailyMarks$ = this.select('dailyMarks');

  readonly userType$ = this.select('userMarksAttemptsAndLimits').pipe(map((data) => data?.user_type));
  readonly nextRefresh$ = this.select('userMarksAttemptsAndLimits').pipe(map((data) => data?.next_refresh));

  readonly remainingMarks$ = this.select('userMarksAttemptsAndLimits').pipe(
    map((data) => {
      if (!data || data.spent_points === null || data.point_limit === null) {
        return 0;
      }

      return Math.max(data?.point_limit - data.spent_points, 0);
    }),
  );
}
