import { Injectable } from '@angular/core';
import { Store } from '@examdojo/state';
import { createStore, withProps } from '@ngneat/elf';
import { UserMarksState } from './user-marks.model';

function createInitialState(): UserMarksState {
  return {};
}

@Injectable({ providedIn: 'root' })
export class UserMarksStore extends Store<UserMarksState> {
  constructor() {
    super(createStore({ name: 'user-marks' }, withProps<UserMarksState>(createInitialState())));
  }
}
