import { A11yModule } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { TimeAgoModule } from '@examdojo/core/date-time';
import { ButtonComponent } from '@examdojo/ui/button';
import { ImageComponent } from '@examdojo/ui/image';
import { TranslocoPipe } from '@jsverse/transloco';
import { map } from 'rxjs';
import { RootUrlParts } from './../../../../../app.model';
import { SettingsUrlParts } from './../../../../../settings/settings.model';
import { UserMarksQuery } from './../../../../../user/mark/user-marks-query';

@Component({
  selector: 'dojo-free-grading-menu',
  imports: [TranslocoPipe, ButtonComponent, TimeAgoModule, A11yModule, ImageComponent],
  templateUrl: './free-grading-menu.component.html',
  styleUrl: './free-grading-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeGradingMenuComponent {
  constructor(
    private readonly userMarksQuery: UserMarksQuery,
    private readonly router: Router,
  ) {}

  readonly state = connectState({
    userType: this.userMarksQuery.userType$,
    remainingMarks: this.userMarksQuery.remainingMarks$,
    nextRefresh: this.userMarksQuery.nextRefresh$.pipe(map((date) => (date ? new Date(date) : null))),
  });

  redirectToBilling() {
    this.router.navigate([RootUrlParts.Settings, SettingsUrlParts.Billing]);
  }
}
