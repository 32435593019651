<div class="flex flex-col items-center justify-center p-4 text-center" cdkTrapFocus cdkTrapFocusAutoCapture="true">
  <div class="flex flex-col gap-2">
    @if (state.userType === 'PAID') {
      <dojo-image
        class="mx-auto"
        [src]="'assets/images/sidebar/coin-unlimited.svg'"
        [alt]="'billing.unlimited_credits' | transloco"
        [classes]="'w-[64px] h-[64px]'"
      ></dojo-image>
    } @else {
      <div class="aligner p-1.5">
        <dojo-image
          [src]="'assets/images/sidebar/belt-coin.svg'"
          [alt]="'billing.marks_grading_coin' | transloco"
          [classes]="'w-[54px] h-[54px]'"
        ></dojo-image>
      </div>
    }
    <div class="text-primary-exam text-display-xs font-semibold">
      @if (state.userType === 'PAID') {
        {{ 'billing.unlimited_credits' | transloco }}
      } @else {
        {{
          'billing.marks_grading_coin'
            | transloco: { count: state.remainingMarks, formattedCount: state.remainingMarks }
        }}
      }
    </div>
    <div class="text-secondary text-md font-normal-exam">
      @if (state.userType === 'PAID') {
        {{ 'billing.pro_unlocked' | transloco }}
      } @else {
        {{ 'billing.marks_grading_explanation' | transloco }}
      }
    </div>
  </div>

  @if (state.userType !== 'PAID') {
    @if (state.nextRefresh) {
      <div class="text-secondary text-md mt-4 flex gap-1 font-semibold">
        <span>{{ 'billing.refreshes_in' | transloco }}</span>
        <dojo-time-ago
          class="inline text-red-500"
          [relativeConfig]="{
            showIfDaysDiff: 14
          }"
          [date]="state.nextRefresh"
        ></dojo-time-ago>
      </div>
    }
    <dojo-button
      [autoFocus]="true"
      class="special-button mt-4 w-full overflow-hidden"
      (click)="redirectToBilling()"
      [customColor]="'amber'"
    >
      <span>{{ 'billing.upgrade_to_pro' | transloco }}</span>
      <span class="stripes">
        <span class="right-stripe"></span>
      </span>
    </dojo-button>
  }
</div>
