import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { connectState } from '@examdojo/angular/util';
import { SyllabusQuery } from '@examdojo/category/v2';
import { LocalizePipe } from '@examdojo/core/i18n';
import { UserQuery } from '@examdojo/user';
import { IonButton } from '@ionic/angular/standalone';
import { RootUrlParts } from '../../../app.model';
import { SettingsUrlParts } from '../../../settings/settings.model';
import { UserMarksQuery } from '../../../user/mark/user-marks-query';
import { FreeGradingMenuComponent } from './components/free-grading-menu/free-grading-menu.component';

@Component({
  selector: 'dojo-sidebar-top-panel',
  imports: [IonButton, LocalizePipe, AsyncPipe, FreeGradingMenuComponent, MatMenuModule],
  templateUrl: './sidebar-top-panel.component.html',
  styleUrl: './sidebar-top-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarTopPanelComponent {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly router: Router,
    private readonly userMarksQuery: UserMarksQuery,
  ) {}

  readonly state = connectState({
    user: this.userQuery.active$,
    activeSyllabus: this.syllabusQuery.active$,
    activeCourse: this.userQuery.active$,
    remainingMarks: this.userMarksQuery.remainingMarks$,
    userType: this.userMarksQuery.userType$,
  });

  navigateToCourse() {
    this.router.navigate([RootUrlParts.Settings, SettingsUrlParts.Courses]);
  }
}
