<div style="height: 44px">
  @if (state.user && state.activeSyllabus) {
    <div class="flex-start flex items-center gap-2">
      <ion-button (click)="navigateToCourse()" fill="clear" class="top-panel-button">
        <div class="flex w-[36px] items-center justify-center">
          <img src="assets/images/sidebar/graduation.svg" />
        </div>
        <span class="ml-2 font-bold">
          @if (state.activeSyllabus) {
            {{ state.activeSyllabus.short_name | localize | async }} {{ state.user.course_level }}
          }
        </span>
      </ion-button>
      <!-- <div class="flex h-11 items-center px-1.5">
    <div class="flex w-[36px] items-center justify-center">
      <img src="assets/images/sidebar/belt-blue.svg" />
    </div>
  </div>
  <div class="flex h-11 items-center pl-1.5 pr-2">
    <div class="flex w-[36px] items-center justify-center">
      <img src="assets/images/sidebar/bamboo.svg" />
    </div>
    <span class="border-red ml-1.5 border text-sm font-bold text-green-500">314</span>
  </div> -->
      <!-- TODO HUNG: fix as soon as we have new designs -->
      <ion-button [matMenuTriggerFor]="marksMenu" fill="clear" class="top-panel-button">
        <div class="flex w-[36px] items-center justify-center">
          @if (state.userType === 'PAID') {
            <img src="assets/images/sidebar/belt-coin-unlimited.svg" />
          } @else {
            <img src="assets/images/sidebar/belt-coin.svg" />
          }
        </div>
        @if (state.userType !== 'PAID') {
          <span class="ml-1.5 text-sm font-bold text-amber-500">
            {{ state.remainingMarks }}
          </span>
        }
      </ion-button>
      <mat-menu #marksMenu="matMenu" class="custom-mat-menu wide-width">
        <ng-template matMenuContent>
          <dojo-free-grading-menu />
        </ng-template>
      </mat-menu>
    </div>
  }
</div>
